<template>
  <preloader-component />
  <h1>{{ check }}</h1>
  <main class="wrapper">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  computed,
  ref,
} from 'vue';

import { useStore } from 'vuex';
import { useWebAppViewport, useWebAppBackButton } from 'vue-tg';
import { useRouter, useRoute } from 'vue-router';

import PreloaderComponent from '@/components/PreloaderComponent.vue';

import { SET_LOADING } from '@/store/actions/application';
import { ENERGY } from '@/store/actions/user';

export default defineComponent({
  name: 'App',
  components: {
    PreloaderComponent,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const check = ref(0);

    const energy = computed(() => store.state.user.energy);
    const maxEnergy = computed(() => store.state.user.maxEnergy);

    const webAppViewport = useWebAppViewport();
    const webAppButton = useWebAppBackButton();

    webAppButton.onBackButtonClicked(() => {
      check.value += 1;

      if (route.meta.back) {
        router.push({ name: (route.meta.back as string) });
      } else if (!window.history.state.back) {
        router.push({ name: 'home' });
      } else {
        router.go(-1);
      }
    });

    onMounted(() => {
      webAppViewport.expand();

      setTimeout(() => {
        store.commit(SET_LOADING, false);
      }, 1000);

      setInterval(() => {
        if (energy.value < maxEnergy.value) {
          store.commit(ENERGY, energy.value + 1);
        }
      }, 1000);
    });

    return {
      check,
    };
  },
});
</script>

<style lang="scss">
.wrapper {
  padding: var(--vertical-offset) var(--horizontal-offset);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &__full {
    height: var(--tg-viewport-stable-height);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: .15s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>