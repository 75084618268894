<template>
  <transition name="preloader-fade">
    <div class="preloader" v-if="loading">
      <div class="preloader-content">
        <h1>MEMECOIN</h1>
        <vue-spinner-bar width="230" :color="'#73FF79'" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

import { VueSpinnerBar } from 'vue3-spinners';

export default defineComponent({
  name: 'PreloaderComponent',
  components: {
    VueSpinnerBar,
  },
  setup() {
    const store = useStore();

    const loading = computed(() => store.state.application.loading);

    return {
      loading,
    };
  },
});
</script>

<style lang="scss">
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: var(--color-dark-1);
  opacity: 1;

  &-fade-leave-active {
    transition: .5s ease-in-out;
  }

  &-fade-leave-to {
    opacity: 0;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    width: 100%;
    height: 100%;

    & > .icon {
      width: 72px;
      height: 72px;
    }
  }
}
</style>