import { SET_LOADING } from '../actions/application';

interface ApplicationState {
  loading: boolean;
}

const state: ApplicationState = {
  loading: true,
};

const mutations = {
  [SET_LOADING]: (currentState: ApplicationState, loading: boolean) => {
    currentState.loading = loading;   // eslint-disable-line
  },
};

export default {
  state,
  mutations,
};
