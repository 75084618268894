<template>
  <div class="energy-component">
    <p class="body-l">
      <slot />
    </p>
    <div class="progress-bar">
      <div
        class="progress-filter"
        :style="{
          width: `${innerValue}%`,
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'EnergyComponent',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const innerValue = computed(() => {
      const value = props.value * 100;

      if (value > 100) {
        return 100;
      }

      if (value < 0) {
        return 0;
      }

      return value;
    });

    return {
      innerValue,
    };
  },
});
</script>

<style lang="scss">
.energy-component {
  position: fixed;
  bottom: 120px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  width: calc(100% - 32px);

  & > p {
    color: var(--color-yellow-1);
  }

  & > .progress-bar {
    position: relative;
    display: flex;
    width: 100%;
    height: 4px;
    background-color: var(--color-light-3);
    border-radius: 12px;

    & > .progress-filter {
      position: absolute;
      height: 100%;
      border-radius: 12px;

      background-color: var(--color-green-3);
    }
  }
}
</style>
