<template>
  <div class="nav-component">
    <router-link :to="{ name: 'soon' }">
      <p class="body-l">Tasks</p>
    </router-link>
    <router-link :to="{ name: 'soon' }">
      <p class="body-l">Partnership</p>
    </router-link>
    <router-link :to="{ name: 'soon' }">
      <p class="body-l">Boost</p>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavComponent',
});
</script>

<style lang="scss">
.nav-component {
  width: 100%;
  padding: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  background: var(--color-light-2);
  border-radius: 20px;

  & > a {
    height: 40px;
    width: 100%;
    color: var(--color-light-1);
    text-decoration: none;
    transition: color .3s ease-out;
    
    display: flex;
    justify-content: center;
    align-items: center;

    border-right: 1px solid var(--color-light-3);
    padding: 20px;

    &:last-child {
      border: none;
    }

    @include hover {
      &:hover {
        color: var(--color-green-3);
      }
    }
  }
}
</style>
