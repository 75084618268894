<template>
  <div class="balance-component">
    <p class="display-l">
      <slot name="balance" />
    </p>
    <div class="coin-icon">
      MEME
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BalanceComponent',
});
</script>

<style lang="scss">
.balance-component {
  margin-top: 50px;
  width: 100%;
  text-align: center;
}
</style>