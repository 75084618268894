import {
  BALANCE,
  ENERGY,
} from '../actions/user';


interface UserState {
  balance: number;
  energy: number;
  maxEnergy: number;
}

const state: UserState = {
  balance: 0,
  energy: 100,
  maxEnergy: 100,
}

const mutations = {
  [BALANCE]: (currentState: UserState, balance: number) => {
    currentState.balance = balance;   // eslint-disable-line
  },
  [ENERGY]: (currentState: UserState, energy: number) => {
    currentState.energy = energy;   // eslint-disable-line
  },
};

export default {
  state,
  mutations,
};
