<template>
  <div class="home-view">
    <balance-component>
      <template #balance>
        {{ balance }}
      </template>
    </balance-component>
    <coin-component />
    <energy-component :value="energy / maxEnergy">
      {{ energy }}/{{ maxEnergy }}
    </energy-component>
    <nav-component />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

import BalanceComponent from '@/components/BalanceComponent.vue';
import CoinComponent from '@/components/CoinComponent.vue';
import EnergyComponent from '@/components/EnergyComponent.vue';
import NavComponent from '@/components/NavComponent.vue';

export default defineComponent({
  name: 'HoveView',
  components: {
    BalanceComponent,
    CoinComponent,
    EnergyComponent,
    NavComponent,
  },
  setup() {
    const store = useStore();

    const balance = computed(() => store.state.user.balance);
    const energy = computed(() => store.state.user.energy);
    const maxEnergy = computed(() => store.state.user.maxEnergy);

    return {
      balance,
      energy,
      maxEnergy,
    };
  },
});
</script>

<style lang="scss">
.home-view {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
