import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_balance_component = _resolveComponent("balance-component")!
  const _component_coin_component = _resolveComponent("coin-component")!
  const _component_energy_component = _resolveComponent("energy-component")!
  const _component_nav_component = _resolveComponent("nav-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_balance_component, null, {
      balance: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.balance), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_coin_component),
    _createVNode(_component_energy_component, {
      value: _ctx.energy / _ctx.maxEnergy
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.energy) + "/" + _toDisplayString(_ctx.maxEnergy), 1)
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_nav_component)
  ]))
}