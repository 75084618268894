<template>
  <div class="coin-component">
    <div
      ref="coin"
      class="coin"
      @mousemove="coinMove"
      @mouseleave="coinLeave"
      @click="coinClick"
      v-if="energy > 0"
    >
      <p>
        MEME
      </p>
    </div>
    <div class="coin skeleton" v-else>
      <p>WAIT</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';

import { useStore } from 'vuex';

import {
  BALANCE,
  ENERGY,
} from '@/store/actions/user';

export default defineComponent({
  name: 'CoinComponent',
  setup() {

    const coin = ref();
    const store = useStore();
    const balance = computed(() => store.state.user.balance);
    const energy = computed(() => store.state.user.energy);

    const getRotate = (multiplier: number, event: any) => {
      let y = (event.clientY / coin.value.offsetHeight * multiplier).toFixed(2);
      let x = (event.clientX / coin.value.offsetWidth * multiplier).toFixed(2);

      return `rotateX(${x}deg) rotateY(${y}deg)`;
    }

    const coinMove = (event: any) => {

      if (coin.value) {
        coin.value.style.transform = getRotate(12, event);
      }
    };

    const coinClick = (event: any) => {
      if (energy.value > 0) {
        coin.value.style.transform = getRotate(20, event);

        store.commit(BALANCE, balance.value + 1);
        store.commit(ENERGY, energy.value - 1);

        setTimeout(() => {
          if (coin.value) {
            coin.value.style.transform = '';
          }
        }, 100);
      }
    };

    const coinLeave = () => {
      coin.value.style.transform = '';
    };

    return {
      coinLeave,
      coinClick,
      coinMove,
      coin,
      energy,
    };
  },
});
</script>

<style lang="scss">
.coin-component {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .coin {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid var(--color-green-1);
    cursor: pointer;
    transition: all .3s ease-out;
    will-change: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    & > p {
      font-size: 50px;
      color: var(--color-green-1);
      transition: font-size .3s ease-out;
      will-change: font-size;
    }

    @include hover {
      &:hover {
        width: 220px;
        height: 220px;

        & > p {
          font-size: 55px;
        }
      }
    }
  }
}
</style>
