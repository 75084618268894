import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useWebAppBackButton } from 'vue-tg';
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      disableBackButton: true,
    },
  },
  {
    path: '/soon',
    name: 'soon',
    component: () => import(/* webpackChunkName: "soon" */ '../views/SoonView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeResolve((to, from, next) => {
  const back = useWebAppBackButton();

  if (to.meta.disableBackButton) {
    back.hideBackButton();
  } else {
    back.showBackButton();
  }

  next();

  const wrapper = document.querySelector('.wrapper');
  wrapper?.scrollTo(0, 0);
});

export default router
